import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { ThemeProvider } from '@material-ui/core';
import { CoreApp } from '@think-zambia-foundation/core-lib/components';
import theme from './theme';
import menu from './menu';
import routes from './routes';

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CoreApp app={{
      name: 'Pinda',
      loginPath: '/login',
      menu,
      routes,
    }}
    />
  </ThemeProvider>,
  document.getElementById('root'),
);
