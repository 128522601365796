import React from 'react';
import PeopleOutlineTwoToneIcon from '@material-ui/icons/PeopleOutlineTwoTone';

const menu = [
  {
    title: 'People',
    items: [
      {
        id: 'a8e9e540c14f4e0dbc86d1cb55405f71',
        claim: 'staffAdmin',
        text: 'Staff',
        secondaryText: 'List of staff',
        link: '/',
        icon: <PeopleOutlineTwoToneIcon style={{ fontSize: '1.5em' }} color="secondary" />,
      },
    ],
  },
];

export default menu;
