import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Think-Zambia-Medium',
    ].join(','),
  },
  palette: {
    secondary: {
      light: '#eeeeee',
      main: '#eeeeee',
      dark: '#eeeeee',
      contrastText: '#000',
    },
    primary: {
      light: '#118C4F',
      main: '#118C4F',
      dark: '#118C4F',
      contrastText: '#fff',
    },
    text: {
      secondary: '#606060',
    },
    info: {
      main: '#606060',
    },
  },
});

export default theme;
