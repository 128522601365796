import {
  StaffList,
  StaffDetails,
} from '@think-zambia-foundation/core-lib/components';

const routes = [
  {
    exact: true,
    path: '/',
    component: StaffList,
    title: 'Staff',
  },
  {
    exact: true,
    path: '/staff/:staffId',
    component: StaffDetails,
    title: 'Staff Details',
  },
];

export default routes;
